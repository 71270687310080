<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">

        <div id="adminContainerDefinition">
            <div id="adminContainerBox">
                <div id="adminContainerTop">
                    <span style="display:inline-block;margin-top:7px; margin-left:10px; height:20px; font-size: 1.2em;">Benzutzereinstellungen</span>
                </div>
                <div id="adminContainerContent">
                    <table class="data_tabel" style="width:100%;" cellpadding="4" cellspacing="0">
                        <tr>
                            <td (click)="changeCurrentSetting('base')" [ngClass]="{'marked': currentSetting == 'base'}">
                                <span style="margin-left: 8px;">Grunddaten</span>
                            </td>
                        </tr><tr>
                            <td (click)="changeCurrentSetting('password')" [ngClass]="{'marked': currentSetting == 'password'}">
                                <span style="margin-left: 8px;">Passwort</span>
                            </td>
                        </tr><tr>
                            <td (click)="changeCurrentSetting('worktime')" [ngClass]="{'marked': currentSetting == 'worktime'}">
                                <span style="margin-left: 8px;">Arbeitszeit</span>
                            </td>
                        </tr><tr>
                            <td (click)="changeCurrentSetting('textblock')" [ngClass]="{'marked': currentSetting == 'textblock'}">
                                <span style="margin-left: 8px;">Textbausteine</span>
                            </td>
                        </tr><tr>
                            <td (click)="changeCurrentSetting('substitute')" [ngClass]="{'marked': currentSetting == 'substitute'}">
                                <span style="margin-left: 8px;">Vertretung</span>
                            </td>
                        </tr><tr>
                            <td (click)="changeCurrentSetting('absence')" [ngClass]="{'marked': currentSetting == 'absence'}">
                                <span style="margin-left: 8px;">Abwesenheit</span>
                            </td>
                        </tr><tr>
                            <td (click)="changeCurrentSetting('signatur')" [ngClass]="{'marked': currentSetting == 'signatur'}">
                                <span style="margin-left: 8px;">Signatur</span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>


        <div id="leftContainerDefinition" class="header1Row footerNone spaceForAdminMenu" >
            <div id="leftContainerTop">
                <span class="currentLocation">
                    <div *ngIf="currentSetting == 'base' && currentSelected.id>0">Grunddaten</div>
                    <div *ngIf="currentSetting == 'password' && currentSelected.id>0">Passwort</div>
                    <div *ngIf="currentSetting == 'worktime' && currentSelected.id>0">Arbeitszeit</div>
                    <div *ngIf="currentSetting == 'textblock' && currentSelected.id>0" >Textbausteine
                       <span class="form">
                           <button (click)="newTextBlock()" style="float: right; margin-right: 6px; margin-top:-3px;"><img src="../design/icons/add.png"> Neu</button>
                       </span>
                    </div>
                    <div *ngIf="currentSetting == 'signatur' && currentSelected.id>0"> Signaturen

                    <span class="form">
                           <button (click)="newSignatur()" style="float: right; margin-right: 6px; margin-top:-3px;"><img src="../design/icons/add.png"> Neu</button>
                       </span>
                </div>
                    <div *ngIf="currentSetting == 'substitute' && currentSelected.id>0">Vertretung</div>
                    <div *ngIf="currentSetting == 'absence' && currentSelected.id>0">Abwesenheit</div>
                </span>

            </div>

            <div id="leftContainerContent" style=" background-color: #f5f5f5;">



                <div class="loading" *ngIf="!data"></div>

                <div *ngIf="currentSetting == 'absence'" style="height:100%;">
                    <table class="form_tabel light" style="width: calc( 100% - 6px ); height: calc( 100% - 10px );" cellspacing="0" cellpadding="4">
                        <tr>
                            <td class="form" style="height:30px; width:50%;">
                                <B>&nbsp;&nbsp;Datum von: </B>
                                <span app-date-picker
                                      [(model)]="selectedAbsence.timestampStringFrom"
                                      [tabindex]="102">
                                </span>
                            </td>
                            <td class="form" style="height:30px; width:50%;">
                                <b>&nbsp;&nbsp;Datum bis: </b>
                                <span app-date-picker
                                      [(model)]="selectedAbsence.timestampStringTo"
                                      [tabindex]="102" >
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="form" colspan="2" style="height: 17px; vertical-align: bottom;">
                                <b>&nbsp;&nbsp;Nachricht</b>
                            </td>
                        </tr>
                        <tr>
                            <td class="form" colspan="2">
                                <editor id="absenceText"
                                        class="noForm"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        initialValue=""
                                        [init]="settings.tinyMceInit"
                                        style="height:calc( 100% - 8px );margin:4px; margin-top: 0;"
                                        [(ngModel)]="selectedAbsence.text">
                                </editor>
                            </td>
                        </tr>
                        <tr>
                            <td class="form" style="height:30px;"  colspan="2">
                                <button (click)="saveAbsence('1')" style="float: right; margin-right: 1px;"><img src="../design/icons/disk.png"> Speichern</button>
                                <button (click)="saveAbsence('0')" style="float: right; margin-right: 1px;"><img src="../design/icons/disk.png"> Deaktivieren</button>
                            </td>
                        </tr>
                    </table>
                </div>

                <div *ngIf="currentSetting == 'base' && currentSelected.id>0">
                    <table class="form"
                           style="width: calc(100% - 40px ); margin-top: 10px; margin-left: 10px; "
                           cellpadding="4" cellspacing="0">
                        <tr>
                            <td style="width:33%;">
                                <span class="formCaption">Anrede*</span>
                                <select [(ngModel)]="currentSelected.salutation"
                                        id="currentSelectedSalutation"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="1">
                                    <option *ngFor="let x of salutations" [value]="x.id">{{ x.name }}</option>
                                </select>

                            </td>
                            <td style="width:33%;">
                                <span class="formCaption">Vorname*</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.first_name"
                                       id="currentSelectedFirstname"
                                       class="cp-textbox"
                                       style="width:100%;"
                                       tabindex="2">
                            </td>
                            <td style="width:33%;">
                                <span class="formCaption" style="margin-left: 15px;">Name*</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.name"
                                       id="currentSelectedName"
                                       class="cp-textbox"
                                       style="width:100%; margin-left: 8px;"
                                       tabindex="3">
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Anmeldename*</span>
                                <input type="text"
                                       [(ngModel)]="currentSelected.nick"
                                       (keyup)="checkNickExists()"
                                       id="currentSelectedNick"
                                       class="cp-textbox"
                                       style="width:calc(100% - 7px);"
                                       tabindex="4">
                            </td>
                            <td colspan="2">
                                <span *ngIf="nickExists" style="left: 0; margin-top: 16px;"><img
                                        src="../design/icons/exclamation.png" style="margin-top: 16px;"></span><span
                                    *ngIf="nickExists"
                                    style="font-size: 11px; margin-top: 16px; margin-right: 20px; float: right;">Anmeldename bereits vergeben!</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="formCaption">Startseite</span>
                                <select [(ngModel)]="currentSelected.startPage"
                                        id="currentSelectedEntryPoint"
                                        class="cp-textbox"
                                        style="width:100%;"
                                        tabindex="5">
                                    <option value="-1">Keine Startseite gewählt</option>
                                    <option *ngFor="let x of dropdownDataEntrypointMenu" [value]="x.id"
                                            [hidden]="x.showOption == false">{{ x.name }}</option>
                                </select>
                            </td>
                            </tr><tr><td>
                            <span class="formCaption">Handynummer für Benachrichtigungen</span>
                            <input type="text"
                                   [(ngModel)]="currentSelected.mobileNumber"
                                   id="currentSelectedMobileNumber"
                                   class="cp-textbox"
                                   style="width:calc(100% - 7px);"
                                   tabindex="6">
                    </td></tr>
                        <tr>
                            <td class="form" colspan="2">
                                <button (click)="save()"
                                        style="width: 100px; float: right; margin-right: -15px; margin-top: 17px;"
                                        tabindex="6">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>

                    </table>
                    </div>
                <div *ngIf="currentSetting == 'signatur'">
                    <div style="background-color:white;left: 0; width: 100%; overflow-y: auto; overflow-x: auto; ">
                        <table class="data_tabel" style="width: 100%; " cellpadding="4" cellspacing="0">
                            <tr *ngFor="let x of signatureData"
                                [ngClass]="{'selected' : selectedSignature == x}">
                                <td (click)="editSignature($event, x)">&nbsp;{{x.name}}</td>
                                <td style="width:24px;" (click)="setBaseSignature(x)">
                                    <img *ngIf="x.base==0" src="../design/icons/star_unactive.png">
                                    <img *ngIf="x.base==1" src="../design/icons/star.png">
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div *ngIf="currentSetting == 'password' && currentSelected.id>0">
                    <table class="form"
                           style="width: calc(100% - 40px ); margin-top: 10px; margin-left: 10px;"
                           cellpadding="4" cellspacing="0">
                        <tr>
                            <td style="width:33%;">
                                <span class="formCaption">Passwort</span>
                                <input [(ngModel)]="newPassword1" type="password" style="width:calc(100% - 18px);"
                                       (keyup)="checkPassword()" tabindex="6" id="changePasswordOne">

                                <div class="checkText"
                                     style="color: #000000 !important; margin-top: 12px; margin-left:5px;"><img
                                        *ngIf="!newPasswordCheck.length" src="../design/icons/exclamation.png"><img
                                        *ngIf="newPasswordCheck.length" src="../design/icons/accept.png"> 8 Zeichen lang
                                </div>
                                <div class="checkText" style="color: #000000 !important;margin-left:5px;"><img
                                        *ngIf="!newPasswordCheck.special" src="../design/icons/exclamation.png"><img
                                        *ngIf="newPasswordCheck.special" src="../design/icons/accept.png"> Sonderzeichen
                                </div>
                                <div class="checkText" style="color: #000000 !important;margin-left:5px;"><img
                                        *ngIf="!newPasswordCheck.upper" src="../design/icons/exclamation.png"><img
                                        *ngIf="newPasswordCheck.upper" src="../design/icons/accept.png"> Großbuchstaben
                                </div>
                            </td>
                            <td style="width: 33%;">
                                <span class="formCaption" style="margin-left: -3px;">Passwort Wiederholung</span>
                                <input [(ngModel)]="newPassword2" type="password"
                                       style="width: calc(100% + 9px); margin-left: -10px;" (keyup)="checkPassword()"
                                       tabindex="7">

                                <div class="checkText" style="color: #000000 !important; margin-top: 12px;"><img
                                        *ngIf="!newPasswordCheck.lower" src="../design/icons/exclamation.png"><img
                                        *ngIf="newPasswordCheck.lower" src="../design/icons/accept.png"> Kleinbuchstaben
                                </div>
                                <div class="checkText" style="color: #000000 !important;"><img
                                        *ngIf="!newPasswordCheck.digits" src="../design/icons/exclamation.png"><img
                                        *ngIf="newPasswordCheck.digits" src="../design/icons/accept.png"> 2 Ziffern
                                </div>
                                <div class="checkText" style="color: #000000 !important;"><img
                                        *ngIf="!newPasswordCheck.same" src="../design/icons/exclamation.png"><img
                                        *ngIf="newPasswordCheck.same" src="../design/icons/accept.png"> Passwörter gleich
                                </div>
                            </td>
                            <td class="form" style="width: 33%;">
                                <button (click)="changePassword()"
                                        style="width: 100px; margin-right: -15px; float: right; margin-top: -38px !important;"
                                        tabindex="8">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>

                <div *ngIf="currentSetting == 'substitute' && currentSelected.id>0" class="form"
                     style="overflow-y: auto; top: 0; left: 0; right: 0; bottom: 0;">
                    <table style="width:100%;" class="form_tabel light" cellpadding="0" cellspacing="0">
                        <tr>
                            <td>
                                <table style="width: 100%;" cellspacing="0" cellpadding="4">
                                    <tr>
                                        <td>
                                            <select [(ngModel)]="selectedSubstituteUserId"
                                                    style="width: 99%;"
                                                    tabindex="101">
                                                <option value="0">Benutzer Wählen</option>
                                                <option *ngFor="let x of dropdownDataUser" [value]="x.id">{{x.userName}}
                                                    , {{x.userFirstName}}</option>
                                            </select>
                                        <td style="width: 100px;" class="form">
                                            <button (click)="putSelectedSubstituteUserAsPossible()"
                                                    style="margin-top: 0;" tabindex="102">
                                                <img src="../design/icons/add.png"> Hinzufügen
                                            </button>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table style="width: 100%;" class="data_tabel" cellpadding="0" cellspacing="0">
                                    <tr style="height: 24px;">
                                        <th>Name</th>
                                        <th style="width: 80px;">Dokumente</th>
                                        <th style="width: 80px;">Vorgänge</th>
                                        <th style="width: 80px;">Priorität</th>
                                        <th style="width: 24px;"></th>
                                    </tr>
                                    <tr *ngFor="let x of possibleSubstituteUsers">
                                        <td>
                                            &nbsp;{{x.name}}, {{x.first_name}}
                                        </td>
                                        <td>
                                            <img src="../design/icons/tick.png"
                                                 *ngIf="x.substitution_fk_user == activeSubstituteUserDocument"
                                                 style="padding-left: 30px;" (click)="unsetSubstituteUser(2)">
                                            <img src="../design/icons/tick_inactive.png"
                                                 *ngIf="x.substitution_fk_user != activeSubstituteUserDocument"
                                                 style="padding-left: 30px;" (click)="setSubstituteUser(x,2)">
                                        </td>
                                        <td>
                                            <img src="../design/icons/tick.png"
                                                 *ngIf="x.substitution_fk_user == activeSubstituteUserProcess"
                                                 style="padding-left: 30px;" (click)="unsetSubstituteUser(1)">
                                            <img src="../design/icons/tick_inactive.png"
                                                 *ngIf="x.substitution_fk_user != activeSubstituteUserProcess"
                                                 style="padding-left: 30px;" (click)="setSubstituteUser(x,1)">
                                        </td>
                                        <td>
                                            <img src="../design/icons/arrow_up.png" *ngIf="x.priority >= 2"
                                                 (click)="increaseSubstituteUserPriority(x)">
                                            <img src="../design/icons/arrow_down.png"
                                                 *ngIf="x.priority < possibleSubstituteUsers.length"
                                                 [ngClass]="{'priorityArrowDown': x.priority < 2}"
                                                 (click)="decreaseSubstituteUserPriority(x)">
                                        </td>
                                        <td style="width: 24px;">
                                            <img src="../design/icons/delete.png" style="margin-left: 4px;"
                                                 (click)="deleteSubstituteUser(x)">
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>

                <div *ngIf="currentSetting == 'textblock' && currentSelected.id>0">
                    <div style="background-color:white;left: 0; width: 100%; overflow-y: auto; overflow-x: auto; ">
                        <table class="data_tabel" style="width: 100%; " cellpadding="4" cellspacing="0">
                            <tr *ngFor="let x of textblocksData"
                                [ngClass]="{'selected' : selectedTextblock.id == x.id}">
                                <td (click)="editTextblock($event, x)">&nbsp;{{x.name}}</td>
                                <td style="width:20px;" (click)="deleteTextBlock(x.id)"><img src="../design/icons/delete.png"></td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div *ngIf="currentSetting == 'absence' && currentSelected.id>0">
                    <div style="background-color:white;left: 0; width: 100%; overflow-y: auto; overflow-x: auto; ">
                        <table class="data_tabel" style="width: 100%; " cellpadding="4" cellspacing="0">
                            <tr *ngFor="let x of absenceData"
                                [ngClass]="{'selected' : selectedAbsence == x}">
                                <td (click)="editAbsence($event, x)">&nbsp;{{x.name}}</td>
                                <td (click)="editAbsence($event, x)">&nbsp;{{x.email}}</td>
                                <td (click)="toggleAbsence($event, x)" style="width:30px; text-align:center;">
                                    <img *ngIf="x.active != 1" src="../design/icons/accept_inactive.png">
                                    <img *ngIf="x.active == 1" src="../design/icons/accept.png">
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone">
            <div id="rightContainerTop">
                <span  *ngIf="currentSetting == 'signatur' && selectedSignature != undefined" >
                        <span>
                            <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedSignaturTab === 1 }" style="margin-right:5px; padding-left: 10px !important; padding-right: 10px !important; margin-left:10px; " (click)="changeTabButton(1)">Signaturen bearbeiten</button>
                            <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedSignaturTab === 2 }" style="margin-right:5px; padding-left: 10px !important; padding-right: 10px !important; " (click)="changeTabButton(2)">Zugewiesene Kontakte</button>
                        </span>
                </span>
            </div>

            <div id="rightContainerContent" style="background: #f5f5f5;">




                <div *ngIf="currentSetting == 'textblock' && currentSelected.id>0" class="form"
                     style="margin-left:10px; overflow-x: hidden; height: 100%; display: inherit;">
                    <table style="width:calc(100% - 10px); height: calc(100% - 10px); margin-top: 10px; " cellpadding="0" cellspacing="0">

                        <tr>
                            <td style="height:50px;">
                                <div class="formCaption">Name</div>
                                <input type="text" style="width:calc( 100% - 7px );" [(ngModel)]="selectedTextblock.name"><br>
                                <div style="margin-top:4px;" class="formCaption" >Text</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <editor class="noForm"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        initialValue="''"
                                        [init]="settings.tinyMceInit"
                                        style="height: 100%;"
                                        [(ngModel)]="selectedTextblock.text">
                                </editor>
                            </td>
                        <tr>
                            <td style="height:30px;">
                                <button (click)="saveTextBlock()" style="width: 100px; float: right;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>

                <div *ngIf="currentSetting == 'project' && currentSelected.id>0" class="form"
                     style="overflow-y: auto; top: 0; left: 0; right: 0; bottom: 0;">
                    <table class="form light" style="width:100%;" cellpadding="6" cellspacing="0">
                        <tr>
                            <th>Name</th>
                            <th>Typ</th>
                        </tr>
                    </table>
                    <table class="data_tabel" style="width:100%;" cellpadding="6" cellspacing="0">
                        <tr *ngFor="let x of userProjects">
                            <td>{{ x.project_name }}</td>
                            <td>{{ x.project_type }}</td>
                        </tr>
                    </table>
                </div>

                <div *ngIf="currentSetting == 'signatur' && selectedSignature != undefined" style="display:inline; overflow-y: auto; top: 0; left: 0; right: 0; bottom: 0;">
                    <table *ngIf="currentSelectedSignaturTab==1" class="form light" style="width:100%;height:100%" cellpadding="4" cellspacing="0">
                        <tr>
                            <td style="height:50px; vertical-align: top;">
                                <div class="formCaption">Name</div>
                                <input type="text" [(ngModel)]="selectedSignature.name" style="width: calc( 100% - 12px );">
                            </td>
                        </tr>
                        <tr style="height:50%" style=" background-color: #f5f5f5;">
                        <td>
                            <div class="formCaption">Kopfzeile</div>
                            <editor
                                    id="header"
                                    class="noForm"
                                    style="height: calc( 100% - 10px );"
                                    [init]="settings.tinyMceInit"
                                    [(ngModel)]="selectedSignature.header"
                                    apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                            ></editor>
                            </td>
                        </tr>
                        <tr>
                            <td style="height:50%">
                                <div class="formCaption">Fußzeile</div>
                                <editor
                                        id="footer"
                                        class="noForm"
                                        style="height: calc( 100% - 10px );"
                                        [init]="settings.tinyMceInit"
                                        [(ngModel)]="selectedSignature.footer"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                ></editor>
                            </td>
                        </tr>
                        <tr style="height: 100px;">
                            <td>
                                <div class="formCaption">Mögliche Platzhalter</div>
                                <table>
                                    <tr><td style="width:25%;"><B>[[anrede]]</B></td><td>Die Anrede Herr,Frau</td></tr>
                                    <tr><td><B>[[nick]]</B></td><td>Der Rufname wenn hinterlegt sonst der Vorname</td></tr>
                                    <tr><td><B>[[vorname]]</B></td><td>Vorname</td></tr>
                                    <tr><td><B>[[name]]</B></td><td>Nachname</td></tr>
                                </table>
                            </td>
                        </tr>

                        <tr style="height:35px;">
                            <td>
                                <button (click)="saveSignature()"
                                        style="float: right; margin-top: 3px; margin-right: 3px;"><img
                                        src="../design/icons/disk.png">&nbsp;Speichern
                                </button>
                            </td>
                        </tr>
                    </table>
                    <div *ngIf="currentSelectedSignaturTab==2">
                    <table *ngIf="signatureAssignedData!==undefined" class="data_tabel" cellspacing="0" cellpadding="4" style="width:100%;">
                        <tr *ngFor="let x of signatureAssignedData">
                            <td>{{x.name}}</td>
                            <td style="width:23px;" (click)="deleteSignatureAssigned(x)">
                                <img src="../design/icons/delete.png">
                            </td>
                        </tr>
                    </table>
                    </div>
                </div>

            </div>
        </div>


    </as-split-area>
</as-split>

 <context-menu #basicMenu>

</context-menu>


<jw-modal id="deleteTextBlock" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/delete.png"><span style="">Textbaustein löschen</span><BR><BR></div>
    </div>
    <div class="modalContent">
        Sind Sie sicher, dass sie den Textbaustein unwiderruflich löschen möchten?
    </div>
    <div class="modalFooter form">
        <button [hidden]="vacationEnded" (click)="deleteTextBlock(0)" style="float: right; margin-top: 3px;"><img
                src="../design/icons/delete.png">&nbsp;Löschen
        </button>
        <button (click)="message.close('deleteTextBlock')" style="float: right; margin-top: 3px;"><img
                src="../design/icons/cross.png">&nbsp;Abbrechen
        </button>
    </div>
</jw-modal>
<jw-modal id="errorMessage" class="shadow form glow">
    <div class="modalHeader">
        <div><img src="../design/icons/exclamation.png"><span style="margin-left:4px;">Hinweis!</span><BR><BR></div>
    </div>
    <div class="modalContent">
        <span [innerHtml]="modelErrorMessage|safe"></span>
    </div>
    <div class="modalFooter form">
        <button (click)="message.close('errorMessage')" style="float: right; margin-top: 3px;"><img
                src="../design/icons/accept.png">&nbsp;OK
        </button>
    </div>
</jw-modal>
