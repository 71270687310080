import { Component, OnInit, ViewChild  } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { SettingsService } from '../../services/settings.service';
import { Router } from '@angular/router';
import { ActivatedRoute, NavigationEnd, RouterEvent } from '@angular/router';
import {ModalService} from '../../_modal';

@Component({
  selector: 'app-document-search',
  templateUrl: './document-search.component.html',
  styleUrls: ['./document-search.component.css']
})
export class DocumentSearchComponent implements OnInit {

  public data = [];
  public searchParameter;
  public readonly searchQuantity: number;
  public searchResultCount: number;
  public showNewItemForm: boolean;
  public expandedSearch = true;
  public expandedSearchPreviousPanelHeight = '';
  public rightContainerWidth;
  public leftContainerWidth;
  public httpOptions = { };
  public selectedDocument;
  public editDocument;
  public documentGroupDataSource = [];
  public documentTagDataSource = [];
  public assignerDatasource = [];
  public tagsList;
  public fileToLoad;
  public editDocumentTag;
  public editDocumentTagDataSource = [];
  public editMode = false;
  public tooltipData = '';
  public showLoading = false;
  public docSearchCustomer = '';
  public searchParameterFilter;
  public informationId = '';
  public ticketId = '';
  public tagDependency: any;
  public documentTop: any;
  public hastMetaExtraInfos = false;
  public currentSelectedTab: number;
  public selectedDocumentLog: any;
  public autoRegRulesData: any;
  public autoRegRulesExpanded: boolean = false;
  public autoRecognitionDebugText: string;

  initSelectedDocument(): void {
    this.selectedDocument = { id: '', name: '', date: '', typ: '', meta_fk_documentgroup: '', meta_fk_company: '', meta_fk_contact: '', meta_title: '', filepath: '', companyName: '', companyShortName: '', contactName: '', contactShortName: '', groupName: '', userAssignedName: '', meta_timestamp_assigned: '', dateString: '', isAttached: 0 };
    this.selectedDocumentLog = [];
  }

  initEditDocument(): void {
    this.editDocument = { id: '', name: '', date: '', typ: '', meta_extra_text : '', meta_fk_documentgroup: '', meta_fk_company: '0', meta_fk_contact: '0', meta_title: '', filepath: '', companyName: '', companyShortName: '', contactName: '', contactShortName: '', groupName: '', userAssignedName: '', dateString: '' };
  }

  initSearchParameter(): void {
      let sort = 'meta_document_date DESC';
      if (this.settings.get('documentListSortSettings')) {
          sort = this.settings.get('documentListSortSettings');
      }
    this.searchParameter = { company: '0', contact: '0', group: '-1', tag: '-1', assigner: '-1', loadFrom : 0, loadQuantity : this.searchQuantity , sort, active : '1', searchText: '', dateFrom : '', dateTo : '', dateType : '1', autoRecognitionRule :'-1'};
    this.searchParameterFilter = {  company: '', contact: '', group: '', tag: '', assigner: '',  active : '', searchText: '', dateFrom : '', dateTo : '', dateType  : '1' };
    this.documentTagDataSource = [];
    this.searchParameter.company = 0;
    this.searchParameter.site = 0;
    this.searchParameter.contact = 0;

    // Fix to clear ContactManager
    setTimeout(() => {
      this.searchParameter.contact = '';
      setTimeout(() => { this.searchParameter.contact = 0; }, 200);
    }, 100);
  }

  constructor(public http: HttpClient, public settings: SettingsService, public message: ModalService, public router: Router, public route: ActivatedRoute) {
    this.documentTop=140;
    this.currentSelectedTab = 1;
    this.initSelectedDocument();
    this.initEditDocument();
    this.searchResultCount = 0;
    this.searchQuantity = 100;
    this.setRightContainerWidth(40);
    this.httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', token: settings.sessionId }) };

    this.initSearchParameter();
    if (typeof this.settings.docsearchcustomer === 'string' ) {
      this.docSearchCustomer = this.settings.docsearchcustomer;
    }
    this.http.post<{count, data, status}>(settings.restBaseUrl + 'document/rightgroups',  {onlyUser:1}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.documentGroupDataSource = data.data;
        }
    );

    this.http.post<{count, data, status}>(settings.restBaseUrl + 'user/list/document/dropdown', {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.assignerDatasource = data.data;
        }
    );


      this.http.post<{status, count, data}>(this.settings.restBaseUrl + 'document/autoRecognition', JSON.stringify(this.searchParameter), this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              if(data.status == "success") {

                  this.autoRegRulesData = data.data;
              } else {
                  this.autoRegRulesData = false;
              }

          }
      );
  }

  ngOnInit() {
      this.handleRouteParameter();

    if ( this.docSearchCustomer !== '' &&  this.docSearchCustomer !== '0' &&  this.docSearchCustomer !== null &&  this.docSearchCustomer !== undefined) {
      this.http.get<{id, name, first_name, company}>(this.settings.restBaseUrl + 'contact/bysearchid/' + this.docSearchCustomer, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
              setTimeout(() => {
                  this.searchParameter.company = data.company;
                  this.searchParameter.contact = data.id;

                  setTimeout(() => {
                      this.search(false);
                      this.settings.docsearchcustomer = '';
                      this.docSearchCustomer = '';
                  }, 500);

              }, 500);


          }
      );
    }
    setTimeout(() => {
      document.getElementById('searchText').focus();
    }, 300);
  }

    backToProcess()
    {
        this.http.put<any>(this.settings.restBaseUrl + 'document/process', {
            tid: this.ticketId
        }, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.router.navigate(['/' + data.message]);
            }
        );
    }

    attach()
    {
        if(this.informationId != ''){

            this.http.put<any>(this.settings.restBaseUrl + 'information/attach', {
                tid: this.informationId.replace('information:', ''),
                did: this.selectedDocument.id
            }, this.httpOptions)
                .pipe(map(data => data)).subscribe(
                data => {
                    this.router.navigate(['/core-information/' + this.informationId.replace('information:', '') + '/creatorSpace']);
                }
            );
        }

        else if(this.ticketId != ''){
        this.http.put<any>(this.settings.restBaseUrl + 'document/attach', {
            tid: this.ticketId,
            did: this.selectedDocument.id
        }, this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.selectedDocument.isAttached = 1;
                this.router.navigate(['/' + data.message]);
            }
        );}
    }

    searchSortChange(loadNextSearchResults: boolean) {
        this.settings.set('documentListSortSettings', this.searchParameter.sort, 0);
        this.search(loadNextSearchResults);
    }

  search(loadNextSearchResults: boolean): void {
    setTimeout(() => {
      this.showLoading = true;
    }, 70);
    if (loadNextSearchResults) { this.searchParameter.loadFrom = this.searchParameter.loadFrom  + this.searchQuantity;
    } else { this.searchResultCount = 0; this.data = []; this.searchParameter.loadFrom = 0; }

    this.http.post<{count, data}>(this.settings.restBaseUrl + 'document', JSON.stringify(this.searchParameter), this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (data.data) {
            this.data = this.data.concat(data.data);
            if (this.searchParameter.loadFrom === 0) {
              this.searchResultCount = data.count;
            }
          }
          setTimeout(() => {
            this.showLoading = false;
            this.settings.docsearchcustomer = '';
          }, 50);
          }
    );
  }

  startTextSearchFromContextmenu(element): void  {
    this.searchParameter.text = element.name;
    this.search(false);
  }

  resetSearch(): void {
    this.initSearchParameter();
    this.search(false);
  }

    handleRouteParameter() {
        // Übergabewerte in der route verarbeiten.
        this.route.paramMap.subscribe(params => {
            if ( params.get('id') ) {
                let information = params.get('id').match('information')
                if(!information){
                    this.ticketId = params.get('id');} else{this.informationId = params.get('id');}
            }

        });
    }

  toggleExpandedSearch(): void {
    let newValue = '106px';
    this.expandedSearch = !this.expandedSearch;
    this.expandedSearchPreviousPanelHeight = document.getElementById('leftContainerContent').style.top;
    if (this.expandedSearchPreviousPanelHeight === '80px') {
      newValue = '106px';
    }
    if (this.expandedSearchPreviousPanelHeight === '106px') {
      newValue = '80px';
    }
    document.getElementById('leftContainerContent').style.top = newValue;
  }

  loadDocumentTagDataSource() {
    this.documentTagDataSource = [];
    this.searchParameter.documentTag = -1;
    this.http.post<any[]>(this.settings.restBaseUrl + 'document/tag/' + this.searchParameter.group, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.documentTagDataSource = data;
        }
    );
  }

  getEditDocumentTags() {
    this.editDocumentTag = '-1';
    this.http.post<{status, data, count}>(this.settings.restBaseUrl + 'tag', { modul_dependency: this.editDocument.meta_fk_documentgroup, active: 1 }, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.editDocumentTagDataSource = data.data;
        }
    );
  }

  new(): void {
    this.initSelectedDocument();
    this.showNewItemForm = true;
    document.getElementById('currentSelectedName').focus();
  }

   edit($event: MouseEvent, item: any): void  {
    this.documentTop = 140;
    this.hastMetaExtraInfos = false;
    this.tooltipData = '';
    this.editMode = false;
    this.tagsList = [];
    this.editDocumentTagDataSource = [];
    this.http.get<any[]>(
        this.settings.restBaseUrl + 'document/getMetadata/' + item.id, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          if (item.meta_title) {
            this.tooltipData += '<strong>' +  item.meta_title + '</strong> - ';
          }
          this.tooltipData += item.name;
          this.selectedDocument = item;
          this.selectedDocument.isAttached = 0;
          this.editDocument = item;
          this.editDocument.dateString = data[0].dateString;
          this.editDocument.testDocument = data[0].testDocument;
          this.editDocument.meta_fk_company = data[0].company;
          this.editDocument.meta_fk_contact = data[0].contact;
          this.editDocument.meta_extra_text = data[0].meta_extra_text;
          this.editDocument.meta_timestamp_assigned = data[0].meta_timestamp_assigned;
          this.tagsList = data[0].tags;
          this.fileToLoad = item.filepath;
          if(this.currentSelectedTab===2) { this.loadLog(); }
        }
    );
  }

  startEdit() {
    this.editMode = true;
    this.editDocumentTag = '-1';
    this.getEditDocumentTags();
  }

  saveEdit() {

      if(((this.editDocument.meta_fk_company !== 0 || this.editDocument.meta_fk_contact !== 0) && this.editDocument.meta_fk_documentgroup !== '-1' && this.editDocument.dateString !== ''))
      { let tagIds = '';
          // tslint:disable-next-line:prefer-for-of
          for (let index = 0; index < this.tagsList.length; ++index) {
              tagIds += this.tagsList[index].id + ';';
          }
          const saveData = {
              id: this.editDocument.id,
              company: this.editDocument.meta_fk_company,
              contact: this.editDocument.meta_fk_contact,
              doctitel: this.editDocument.meta_title,
              group: this.editDocument.meta_fk_documentgroup,
              meta_extra_text: this.editDocument.meta_extra_text,
              date: this.editDocument.dateString,
              tags: this.tagsList,
              edit: '1'
          };
          this.http.put<any[]>(this.settings.restBaseUrl + 'document/saveMetadata', saveData, this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data => {
                  this.documentTop = 140;
                  this.editMode = false;
                  this.data = [];
                  this.search(false);
              }
          );}
      else{
          this.message.open('noDocumentGroup');
   }
  }

  documentTagClicked() {
    // tslint:disable-next-line:triple-equals
    if (this.editDocumentTag != '-1') {
      let tagExists = false;
      this.tagsList.forEach((element) => {
        if (element.id === this.editDocumentTag) {
          alert('Dieser Tag ist bereits zugewiesen.');
          tagExists = true;
        }
      });

      setTimeout(() => {
        if (!tagExists) {
          this.setDocumentTag();
        }
      }, 100);
    }
  }

  setDocumentTag() {
    this.http.get<{ id, name,tags_dependency }>(this.settings.restBaseUrl + 'tag/' + this.editDocumentTag, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          this.tagsList.push(data);
            if(data.tags_dependency.length > 0 ) {
                // Es gibt Abhängigkeiten > Dialog öffnen
                this.tagDependency = data.tags_dependency;
                this.message.open('tagDependencyDialog');
            }
        }
    );
  }

  tagRemove(item) {
    const index = this.tagsList.indexOf(item);
    this.tagsList.splice(index, 1);
  }

  deactivate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'template/active/' + item.id + '/0', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initSelectedDocument();
            this.showNewItemForm = false;
          });
    }
  }

  activate(item): void  {
    const deleteItems = window.confirm('Soll der markierte Eintrag wirklich gelöscht werden?');
    if (deleteItems) {
      this.http.put<any[]>(
          this.settings.restBaseUrl + 'template/active/' + item.id + '/1', {}, this.httpOptions)
          .pipe(map(data => data)).subscribe(
          data => {
            setTimeout(() => {
              this.search(false);
            }, 300);
            this.initSelectedDocument();
            this.showNewItemForm = false;
          });
    }
  }

  changeSort(itemId, sortAction): void  {
    this.http.put<any[]>(
        this.settings.restBaseUrl + 'template/sort/' + itemId + '/' + sortAction, {}, this.httpOptions)
        .pipe(map(data => data)).subscribe(
        data => {
          setTimeout(() => {
            this.search(false);
          }, 300);
          this.initSelectedDocument();
          this.showNewItemForm = false;
        });
  }

  setRightContainerWidth(width): void  {
    this.rightContainerWidth = width;
    this.leftContainerWidth = 100 - this.rightContainerWidth;
  }

  searchOnReturnPressed(event): void {
    if (event.which === 13 || event.key === 'Enter') { this.search(false); }
  }

    goTo(selector,tid) {
        let destination;
        if (typeof selector === 'string' || selector instanceof String) {
            destination = selector;
        } else {
            destination = selector.options[selector.selectedIndex].value;
        }
        if(tid!==null) {
            destination += '/' + tid;
        }
        this.router.navigate(['/' + destination]);
    }

    favoriteSelected() {
        const searchTag = this.searchParameter.tag;
        this.loadDocumentTagDataSource();
        setTimeout(() => {
            this.searchParameter.tag = searchTag;
            setTimeout(() => { this.search(false); }, 200);
        }, 100);
    }

    createProcessFromDocument() {
        this.http.put<any>(this.settings.restBaseUrl + 'process/createFromDocumentPath' , JSON.stringify(this.selectedDocument), this.httpOptions)
            .pipe(map(data => data)).subscribe(
            data => {
                this.router.navigate(['/' + data.message]);
            }
        );
    }

    markAsTestDocument(setActive: boolean) {
      if(setActive) {
          this.http.put<any>(this.settings.restBaseUrl + 'document/testDocument' , {id:this.selectedDocument.id}, this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data => {
                  this.selectedDocument.testDocument = data.message;
              }
          );
      } else {
          this.http.delete<any>(this.settings.restBaseUrl + 'document/testDocument/' + this.selectedDocument.id , this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data => {
                  this.selectedDocument.testDocument = null;
              }
          );
      }

    }

    addTagDependency(tag) {
        this.message.close('tagDependencyDialog');
        this.editDocumentTag = tag.id;
        this.documentTagClicked();
    }

    toggleExtraMetaText() {
      if(this.documentTop == 140) {
          this.documentTop = 290;
      } else{
          this.documentTop = 140;
      }

    }

    changeTabButton(tabId): void {
        this.currentSelectedTab = tabId;
        if(this.currentSelectedTab===2) { this.loadLog(); }
    }

    private loadLog() {
          this.http.post<{count, data, status}>(this.settings.restBaseUrl + 'log',  {source:2,source_id:this.selectedDocument.id}, this.httpOptions)
              .pipe(map(data => data)).subscribe(
              data => {
                  this.selectedDocumentLog = data.data;
              }
          );
    }

    testAutotagRule() {

    }

    toggleAutoTagUI() {
        this.autoRegRulesExpanded = !this.autoRegRulesExpanded;
    }

    viewAutoRecognitionDebugData(x: any) {
        this.autoRecognitionDebugText = x.auto_debug;
        this.message.open('autoRecognitionDebugData');
    }
}
