<span id="calendarViewArea" class="noSelection">
    <table style="width: 100%;height: 100%; margin-top:-2px;">
        <ng-container *ngIf="viewMode==0">
            <tr>
                <td colspan="7">
                    <div style="width:100%; height:100%;vertical-align: top;overflow: auto;">
                        <table class="timelineTable">
                            <ng-container *ngFor="let x of ngModel;index as currentMonth">
                                <tr>
                                    <td id="monthMark_{{x.monthStart}}_{{x.yearStart}}"  *ngIf="ngModel[currentMonth-1] == undefined || x.monthStart != ngModel[currentMonth-1].monthStart" colspan="4" style="text-align: center; background-color: #fff0d4;">
                                        {{monthsNames[x.monthStart-1]}} ({{x.monthStart}}) {{x.yearStart}}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width:100px;">
                                        {{x.dayStart | number:'2.0' }} {{ x.monthStart+'/'+x.dayStart+'/'+x.yearStart | date :'LLL'}} ({{x.monthStart | number:'2.0' }}) {{x.yearStart}}
                                    <td style="width:24px;">
                                        {{ x.monthStart+'/'+x.dayStart+'/'+x.yearStart | date :'EEE'}}
                                    </td>
                                    <td style="width:80px;">
                                        {{x.timeStart}} - {{x.timeEnd}}
                                    </td>
                                    <td>
                                        {{x.name}}
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </div>
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="viewMode>0 && viewMode<11">
            <tr>
                <td colspan="7" style="width:100%; height:100%;">
                    <div>
                        <ng-container *ngFor="let day of [].constructor(viewMode); let dayCount = index">
                                <div style="top:41px;background:rgb(239 239 239);z-index:9999999; display: inline-block;
                                        height:40px; border-bottom: 1px solid #737373;border-left: 1px solid #d3d3d3;border-right: 1px solid #d3d3d3;
                                        text-align: center; line-height: 3; font-size: 13px;
                                        left:50px; top:41px; position: relative;
                                        width: calc({{100/viewMode}}% - {{(78/viewMode)}}px ) " >
                                    {{getDatePlusDays(dayCount,'string')}}
                                </div>
                        </ng-container>
                    </div>
                    <div style="width:100%; height:100%;vertical-align: top;overflow: auto;" id="calendarViewScrollArea{{overlayId}}" >
                        <div style="position: relative;" class="calendarView">

                            <div id="calendarViewInfoContainer" class="dateViewContainer shadow" *ngIf="detailViewIsVisible" >
                                <div style="margin: 20px;">
                                <h1>
                                    <img *ngIf="currentSelectedDateItem.tid>0" (click)="openProcess(currentSelectedDateItem.tid)" src="../design/icons/link.png" style="vertical-align: text-top;">
                                    {{currentSelectedDateItem.name}}
                                </h1>
                                <strong>Am</strong> {{formatDate(currentSelectedDateItem)}} <strong>von</strong> {{currentSelectedDateItem.timeStart}} <strong>bis</strong> {{currentSelectedDateItem.timeEnd}}<BR>
                                <strong>Kalender: </strong>{{currentSelectedDateItem.owner_name}}<BR>
                                <ng-container *ngIf="currentSelectedDateItem.text!=''">
                                    <hr >
                                    <div *ngIf="currentSelectedDateItem.text!=''" [innerHTML]="currentSelectedDateItem.text | safe" style="text-align: justify;"></div><BR>
                                </ng-container>
                                </div>
                                <div *ngIf="currentSelectedDateItem.tid>0" style="overflow: auto;">
                                    <app-process-progress [loadTid]="currentSelectedDateItem.tid"></app-process-progress>
                                </div>
                            </div>

                            <div *ngIf="isDateVisibleInSelectedTImeRange(todayDate)" style="z-index:9999999;position: absolute; height:2px; background: orange;
                                    top:{{getCurrentTimePosition()}}px; left:{{dayViewCalcLeftPosition(todayDate) }}px; width:calc({{100/viewMode}}% - {{(48/viewMode)}}px;
                                        -webkit-box-shadow: 0px 0px 3px 2px rgba(255,255,255,0.59);
                                        box-shadow: 0px 0px 3px 2px rgba(255,255,255,0.59);"></div>
                            <div *ngIf="isDateVisibleInSelectedTImeRange(todayDate)"
                                    style="z-index:9999999;position: absolute; background: orange;
                                    top:{{getCurrentTimePosition()-4}}px; left:{{dayViewCalcLeftPosition(todayDate) -6}}px;
                                    border-radius: 50%;
                                    width: 10px;
                                    height: 10px;"></div>
                            <div *ngIf="isDateVisibleInSelectedTImeRange(todayDate)"
                                    style="z-index:9999999;
                                    top:{{getCurrentTimePosition()-8}}px;
                                    left: 40px;
                                    position: absolute;
                                    width: 10px;
                                    z-index: 9999999;
                                    width: 0px;
                                    height: 0px;
                                    border-top: 8px solid transparent;
                                    border-bottom: 8px solid transparent;
                                    border-right: 10px solid orange;"></div>
                            <ng-container *ngFor="let date of ngModel" >
                                    <span *ngIf="isDateVisibleInSelectedTImeRange(date)"
                                          [contextMenu]="basicMenu"
                                          [contextMenuValue]="date"
                                          (dblclick)="editDate(date)"
                                          (mousedown)="handelMouseDownEvent($event, date);"
                                          (mouseup)="handleMouseUpEvent($event);"
                                          class="date {{'color'+date.color}}"
                                          [ngClass]="{'selectedDate' : currentSelectedDateItem == date}"
                                          style="width:100%;
                                          z-index:99998;
                                          position: absolute;
                                          left:{{dayViewCalcLeftPosition(date)}}px;
                                          height:{{ ( getTimeAsMathValue(date.timeEnd) - getTimeAsMathValue(date.timeStart) ) * (dayCellHeight+2)}}px;
                                          width:calc({{100/viewMode/date.layer}}% - {{((58/viewMode/date.layer)+12)}}px;
                                          top:{{(getTimeAsMathValue(date.timeStart)*(dayCellHeight+2)) +40 }}px;">
                                        <span style="width:100%;">
                                            <img *ngIf="date.tid>0" src="../design/icons/link.png" style="float: right; margin-right:6px; margin-top: -2px;">
                                            <img *ngIf="date.fk_calendar_recuring_date!=0 && date.id!=0" src="../design/icons/simple_clock_orange.png" style="float: right; margin-right:6px; ">
                                            <img *ngIf="date.fk_calendar_recuring_date!=0 && date.id==0" src="../design/icons/simple_clock.png" style="float: right; margin-right:6px; ">
                                            <strong>{{date.timeStart}} bis {{date.timeEnd}}:</strong> {{date.name}}
                                        </span>
                                    </span>
                                    <span *ngIf="isDateVisibleInSelectedTImeRange(date)"
                                          (mousedown)="startDateItemResize($event, date);"
                                            style="cursor:row-resize;z-index:999999;height:6px;position: absolute; background:transparent;
                                            left:{{dayViewCalcLeftPosition(date)}}px;
                                            width:calc({{100/viewMode}}% - {{(54/viewMode)}}px;
                                            top:{{(getTimeAsMathValue(date.timeStart)*(dayCellHeight+2)) + 38 + (( getTimeAsMathValue(date.timeEnd) - getTimeAsMathValue(date.timeStart) ) * (dayCellHeight+2))}}px;">
                                    </span>
                            </ng-container>

                            <div style="position: relative;width: 50px; display: inline-block;float:left; ">
                                <div style="z-index:999998;position: relative; height:40px; width: 50px; border-bottom: 1px solid rgb(115, 115, 115); position: fixed; background: rgb(239, 239, 239);" >
                                    <div style="margin-top:12px; text-align: center; font-size: 1.2em; font-weight: bold;">{{getCurrentTime()}}<BR>
                                   >>{{status}}<<</div>
                                </div>
                                <div style="position: relative; margin-top:40px;">


                                    <div style="position: relative; height:{{dayCellHeight}}px; width: 50px; border: 1px solid transparent;" *ngFor="let item of [].constructor(24); let i = index" >
                                        <div [id]="(i+1)+'oClock'" *ngIf="i!=23" style="position:absolute; bottom: -6px; margin-left:4px;font-size:10px;" >{{i+1 | number:'2.0'}}:00</div>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngFor="let day of [].constructor(viewMode); let dayCount = index">
                                <div style="position: relative;width: calc({{100/viewMode}}% - {{54/viewMode}}px );display: inline-block ;float:left; ">
                                    <div style="position: relative; margin-top:40px;" class="dontShowDropElement">
                                        <div style="position: relative; height:{{dayCellHeight}}px; width: 100%;border: 1px solid #dadada;"
                                             *ngFor="let item of [].constructor(24); let i = index"
                                             (dblclick)="newDate(dayCount ,i,$event.target.offsetHeight,$event.offsetY,'','')"
                                             (click)="closeDetailView()"
                                             cdkDropList [cdkDropListData]="{dayCount:dayCount,hour:i}" (cdkDropListDropped)="handleDropEvent($event)" >
                                        </div>
                                    </div>
                                    <ng-container *ngFor="let x of timeBoxData">
                                    <div *ngIf="getDatePlusDays(dayCount,'weekday') == x.day"
                                            tooltip="{{x.text}}" name="timebox"
                                            style="position: absolute;
                                            pointer-events: none;
                                            width: 100%;
                                            left: 0px;
                                            z-index:1;
                                            background: rgba(145,145,145,0.31);
                                            -webkit-border-radius: 4px;
                                            -moz-border-radius: 4px;
                                            border-radius: 4px;
                                            border: 1px solid #9c9c9c;
                                            font-size: 11px;
                                            color: #2d2d2d;
                                            height: 100px;
                                            margin: 0;
                                            top:{{(getTimeAsMathValue(x.start_time)*(dayCellHeight+2)) +40 }}px;
                                            height:{{ ( getTimeAsMathValue(x.end_time) - getTimeAsMathValue(x.start_time) ) * (dayCellHeight+2)}}px">
                                            <div style="margin: 5px; color: #2d2d2d;">{{x.text}}</div>
                                            <div *ngIf="( getTimeAsMathValue(x.end_time) - getTimeAsMathValue(x.start_time) ) * (dayCellHeight+2) > 100" style="margin: 5px; position: absolute; bottom: calc(50% - 10px);">{{x.text}}</div>
                                            <div *ngIf="( getTimeAsMathValue(x.end_time) - getTimeAsMathValue(x.start_time) ) * (dayCellHeight+2) > 40" style="margin: 5px; position: absolute; bottom: 0px;">{{x.text}}</div>
                                    </div>
                                    </ng-container>
                                </div>

                            </ng-container>
                        </div>

                    </div>
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf="viewMode==11">
            <tr><td>
            <table class="calendarView">
                <tr style="height:24px;">
                    <th>Mo</th>
                    <th>Di</th>
                    <th>Mi</th>
                    <th>Do</th>
                    <th>Fr</th>
                    <th>Sa</th>
                    <th>So</th>
                </tr>
                <tr *ngFor="let rows of calenderDays">
                    <td *ngFor="let x of rows ;let i = index"
                        [attr.data-index]="i"
                        [ngClass]="{
                                'rangeMarked' : x.marked == 1,
                                'lastNextMonth' : !x.currentMonth && i < 5,
                                'lastNextMonthWe' : !x.currentMonth && i > 4,
                                'we' : x.currentMonth && i > 4,
                                'currentSelected' : selectedDay == x.day && selectedMonth == x.month,
                                'today' : todayDate.dayString == x.day && todayDate.monthString == x.month && todayDate.year == x.year,
                                'fixCellHeight' : small==true}"
                        (click)="setSelection(x)" style="vertical-align: top; text-align: left;width:14.3%; height:16.67%" >

                        <div id="{{elementName}}_{{x.day}}" *ngIf="small==true" style="z-index:9999999;width:100%; height: 100%;">
                            <div id="{{elementName}}_{{x.day}}_text" [ngClass]="{ 'smallDay' : true, 'smallDayMarked' : hasDayAnDate(x) }"><span>{{x.day}}</span></div>
                        </div>
                        <div *ngIf="small!=true">
                            <div style=" text-align:right;height:15px; font-size: 13px; width:100%; ">{{x.day}}</div>
                            <ng-container *ngFor="let date of filterDateArray(x.month,currentYear)">
                                <span *ngIf="date.yearStart == currentYear && date.monthStart == x.month && date.dayStart == x.day"
                                      (mousedown)="handelMouseDownEvent($event, date);"
                                      class="date {{'color'+date.color}}">
                                    <span>{{date.name}}</span>
                                </span>
                            </ng-container>
                        </div>
                    </td>
                </tr>
            </table>
            </td></tr>
        </ng-container>
        <ng-container *ngIf="viewMode==12">
            <table style="width:100%; height: 100%;" >
                <tr *ngFor="let item of [].constructor(yearRows); let i1 = index;">
                    <td *ngFor="let item of [].constructor(yearColumns); let i2 = index;"> {{monthView((i1 * yearColumns) + i2 +1 )}}
                        <table class="calendarView">
                            <tr style="height:20px;">
                                <th colspan="7">{{monthNamesLong[(i1 * yearColumns) + i2]}} ({{(i1 * yearColumns) + i2 + 1}})</th>
                            </tr>
                            <tr style="height:20px;">
                                <th>Mo</th>
                                <th>Di</th>
                                <th>Mi</th>
                                <th>Do</th>
                                <th>Fr</th>
                                <th>Sa</th>
                                <th>So</th>
                            </tr>
                            <tr *ngFor="let rows of calenderDays">
                                <td *ngFor="let x of rows ;let i = index" [attr.data-index]="i"
                                    [ngClass]="{
                                            'lastNextMonth' : !x.currentMonth && i < 5,
                                            'lastNextMonthWe' : !x.currentMonth && i > 4,
                                            'we' : x.currentMonth && i > 4,
                                            'currentSelected' : selectedDay == x.day && selectedMonth == x.month,
                                            'today' : todayDate.dayString == x.day && todayDate.monthString == x.month && todayDate.year == x.year,
                                            'fixCellHeight' : small==true}">
                                    <div id="{{elementName}}_{{x.day}}" style="z-index:9999999;width:100%; height: 100%;">
                                        <div id="{{elementName}}_{{x.day}}_text" [ngClass]="{ 'smallDay' : true, 'smallDayMarked' : hasDayAnDate(x) }" (click)="changeToWeek()"><span style="li">{{x.day}}</span></div>
                                    </div>

                                </td>
                            </tr>
                        </table>

                    </td>
                </tr>
            </table>

        </ng-container>
    </table>
    <table class="calendarView" *ngIf="displayMode==1" style="width:100%; height:100%;">
        <tr>
            <th>Monat</th>
            <th colspan="31"></th>
        </tr>
        <tr *ngFor="let x of yearViewData">
            <td >{{x.monthName}}</td>
            <td  *ngFor="let y of x.days" [ngClass]="{
            'lastNextMonth' : y.day > x.daysInCurrentMonth,
             'we' : y.dayName == 'Sa' || y.dayName == 'So',
             'today' : todayDate.day == y.day && todayDate.month == x.month
             }" (click)="selectDayAndMonth(y.day,x.month)">
               <span style="display: inline-block" *ngIf="y.day <= x.daysInCurrentMonth" >
                   <span style="font-weight: bold;">{{y.dayName}} {{y.day}}.</span> <BR>
                   <span style="font-size: 0.9em;">KW:{{y.calendarWeek}}</span>
               </span>
            </td>
        </tr>
    </table>
</span>

<context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="deleteDate($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Termin Löschen
        </span>
    </ng-template>
</context-menu>
