<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header4Row footerNone" >
            <div id="leftContainerTop">
                <span *ngIf="data!=undefined&&searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="data!=undefined&&searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation"  *ngIf="ticketId == '' && informationId == ''">
                    Dokumente > <div class="pathSelector">▼ <select #modSel class="modul_selector" (change)="goTo(modSel, ticketId)">
                        <option value="document-search" selected>Suche</option>
                        <option value="document-assign">Eingang</option>
                        <option value="document-portal">zur Vorlage</option>
                    </select></div>
                </span>
                <span class="form currentLocation" *ngIf="ticketId != ''" style="display: inline-block; color: white;" >
                    Dokumente > Anhängen aus Suche >
                    <button *ngIf="ticketId != ''" (click)="goTo('document-assign', ticketId)" style="margin-top:-3px;">
                        <img src="../design/icons/arrow_switch.png"> Aus Freigabe Anhängen
                    </button>

                    <button *ngIf="ticketId != ''" (click)="backToProcess()" style="margin-top:-3px;">
                        <img src="../design/icons/arrow_left.png"> Dokumente anhängen beenden
                    </button>
                </span>

                <span class="form currentLocation" *ngIf="informationId != ''" style="display: inline-block; color: white;" >
                    Dokumente > Anhängen aus Suche >
                    <button *ngIf="ticketId != ''" (click)="goTo('document-assign', informationId)" style="margin-top:-3px;">
                        <img src="../design/icons/arrow_switch.png"> Aus Freigabe Anhängen
                    </button>
                </span>


                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.searchText"
                           id="searchText"
                           style="width:158px;"
                           tabindex="1">

                    <app-contact-manager
                            mode="dms_search"
                            [(company)]="searchParameter.company"
                            [(contact)]="searchParameter.contact"
                            style="width:248px; margin-left: -1px;" [tabIndex]="2">
                    </app-contact-manager>


                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <app-list-search-filter [(model)]="searchParameter" [filter]="searchParameterFilter" [list]="20" (callback)="favoriteSelected()" style="margin-left:2px;">
                    </app-list-search-filter>

                    <button (click)="search(false)" style="margin-left: 1px; width: 80px; min-width: 80px;" tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <div *ngIf="autoRegRulesData" style="display: inline-block; float: right; margin-right: 4px;" class="form">
                        <span *ngIf="autoRegRulesExpanded" class="form">
                            <select style="margin-right:2px;" [(ngModel)]="searchParameter.autoRecognitionRule">
                                <option value="-1">Regel wählen</option>
                                <option *ngFor="let x of autoRegRulesData" [value]="x.id">{{x.name}}</option>
                            </select>
                        </span>
                        <button style="width:14px; min-width: 14px;" (click)="toggleAutoTagUI()" tooltip="Automatische Erkennung- Regeln Testen anzeigen.">
                            <img *ngIf="!autoRegRulesExpanded" style="margin-left:-6px;" src="../design/icons/sort_arrow_left.png">
                            <img *ngIf="autoRegRulesExpanded" style="margin-left:-5px;" src="../design/icons/sort_arrow_right.png">
                        </button>
                    </div>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">

                        <select (change)="loadDocumentTagDataSource()"
                                [(ngModel)]="searchParameter.group"
                                id="searchParameterDocumentGroup"
                                style="width:165px;"
                                tabindex="7">
                            <option value="-1">Alle Dokumentengruppen</option>
                            <option *ngFor="let x of documentGroupDataSource" [value]="x.id">{{x.name}}</option>
                        </select>

                        <select [(ngModel)]="searchParameter.tag"
                                id="searchParameterDocumentTag"
                                style="width:141px;margin-left: -1px;"
                                tabindex="7">
                            <option value="-1">Alle Tags</option>
                            <option *ngFor="let x of documentTagDataSource" [value]="x.id">{{x.name}}</option>
                        </select>

                        <span app-date-picker
                              [(model)]="searchParameter.dateFrom"
                              [(boundDatepicker)]="searchParameter.dateTo"
                              id="dateFrom"
                              tabindex="102"
                              first="true"
                              style="margin-left: -1px;">
                        </span>

                        <span app-date-picker
                              [(model)]="searchParameter.dateTo"
                              [(boundDatepicker)]="searchParameter.dateFrom"
                              id="dateTo"
                              tabindex="102"
                              style="margin-left: 2px;">
                        </span>

                        <select [(ngModel)]="searchParameter.dateType"
                                id="dateType"
                                style="width:130px;margin-left: 2px;"
                                tabindex="9">
                            <option value="1">Dokumentendatum</option>
                            <option value="2">Eingangsdatum</option>
                            <option value="3">Beide Daten</option>
                        </select>

                        <select [(ngModel)]="searchParameter.assigner"
                                id="assigner"
                                style="width:150px;margin-left: -1px;"
                                tabindex="9">
                            <option value="-1">Alle Benutzer</option>
                            <option *ngFor="let x of assignerDatasource" [value]="x.id">{{x.name}}</option>
                        </select>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%; margin-top: 6px;">
                    <tr>
                        <th style="width:20px;">
                            <span app-sort-switch (onSortChanged)="searchSortChange(false)" [(model)]="searchParameter.sort" name=" "  sortColumn="typ"></span>
                        </th>
                        <th style="width:20px;" *ngIf="searchParameter.autoRecognitionRule!=-1">
                            <span app-sort-switch (onSortChanged)="searchSortChange(false)" [(model)]="searchParameter.sort" name=" "  sortColumn="typ"></span>
                        </th>
                        <th style="width:100px;">
                            <span app-sort-switch (onSortChanged)="searchSortChange(false)" [(model)]="searchParameter.sort" name="Eingagangen" sortColumn="timestamp_in" desc="desc"></span>
                        </th>
                        <th style="width:100px;">
                            <span app-sort-switch (onSortChanged)="searchSortChange(false)" [(model)]="searchParameter.sort" name="Dokumentdatum" sortColumn="meta_document_date" desc="desc"></span>
                        </th>
                        <th>
                            <span app-sort-switch (onSortChanged)="searchSortChange(false)" [(model)]="searchParameter.sort" name="Kontakt/Titel/Name" sortColumn="name"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="data != undefined && data.length < 1 && showLoading"><img src="../design/icons/roller.gif" style="margin-left: 45%; margin-top: 25%; width: 50px;"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : selectedDocument.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="edit($event,x)" style="width: 20px;">
                            <img *ngIf="x.typ=='pdf' || x.typ =='PDF'" src="../design/icons/doc_pdf.gif">
                            <img *ngIf="x.typ=='eml' || x.typ =='EML'" src="../design/icons/doc_msg.gif">
                        </td>

                        <td (click)="viewAutoRecognitionDebugData(x)" style="width: 20px;" *ngIf="searchParameter.autoRecognitionRule!=-1">
                            <img *ngIf="x.auto_found==0" src="../design/icons/accept_inactive.png">
                            <img *ngIf="x.auto_found==1" src="../design/icons/accept.png">
                        </td>

                        <td (click)="edit($event,x)" style="width:100px;text-align: center;">
                            {{ x.timestamp_in * 1000 | date:"dd.MM.y HH.mm" }}
                        </td>

                        <td style="width:100px;text-align: center;">
                            <div *ngIf="x.status!=5" (click)="edit($event,x)" >{{ x.meta_document_date * 1000 | date:"dd.MM.y" }}</div>
                            <div *ngIf="x.status==5" (click)="goTo('document-assign/select/' + x.id,null)" style="font-weight: bold;height:100%; padding-top: 4px; cursor: pointer;" >in Freigabe</div>
                        </td>

                        <td (click)="edit($event,x)">
                            <strong>{{x.companyShortName}}</strong>
                            <span *ngIf="x.contactShortName"> ({{x.contactShortName}})</span> <strong>:</strong>
                            <span *ngIf="x.meta_tags"><strong>{{ x.meta_tags }}</strong> - </span>
                            <span *ngIf="x.meta_title"><strong>{{ x.meta_title }}</strong> - </span>
                            {{ x.name }}
                        </td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data!=undefined&&data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop" style="white-space: nowrap;">
                <div style="margin-left:10px;" class="form">

                    <span *ngIf="selectedDocument.id>0 && !editMode" >
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 1 }" style="margin-right:5px;" (click)="changeTabButton(1)">Informationen</button>
                        <button [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 2 }" style="margin-right:5px;" (click)="changeTabButton(2)">Ereignisverlauf</button>
                    </span>

                    <span *ngIf="selectedDocument.id>0 && editMode" style="display: inline-block; margin-left:5px;margin-top:5px; color:white;">
                        Dokument bearbeiten
                    </span>

                    <button *ngIf="selectedDocument.id>0 && !editMode"
                            style="margin-top:2px;min-width:30px;height:30px;top: 0px; margin-right: 5px;float:right; "
                            (click)="startEdit()"><img src="../design/icons/pencil.png"></button>
                    <button *ngIf="selectedDocument.id>0 && !editMode && ticketId == ''"
                            tooltip="Dokument per E-Mail versenden oder Vorgang eröffnen."
                            style="margin-top:2px;min-width:30px;height:30px;top: 0px; margin-right: 5px;float:right; "
                            (click)="createProcessFromDocument()"><img src="../design/icons/page_add.png"></button>

                    <button *ngIf="ticketId != '' && selectedDocument.id > 0 && selectedDocument.isAttached == 0" (click)="attach()" style="float: right; margin-top:2px;width:150px">
                        <img src="../design/icons/accept_inactive.png"> An E-Mail anhängen
                    </button>

                    <button *ngIf="ticketId != '' && selectedDocument.id > 0 && selectedDocument.isAttached == 1" style="float: right; margin-top:2px;width:150px">
                        <img src="../design/icons/accept.png"> Wurde angehängt
                    </button>

                    <button *ngIf="informationId != '' && selectedDocument.id > 0 && selectedDocument.isAttached == 0" (click)="attach()" style="float: right; margin-top:2px;width:150px">
                        <img src="../design/icons/accept_inactive.png"> An Benachrichtigung anhängen
                    </button>

                </div>
            </div>

            <div id="rightContainerContent" style="overflow: hidden;">
                <div *ngIf="selectedDocument.id>0 && !editMode && currentSelectedTab===1" class="form" style="margin-left:5px;">
                    <table style="width:100%;font-size: 12px;" border="0" cellspacing="8" *ngIf="editMode==false">
                        <tr>
                            <td>Titel</td>
                            <td colspan="3" tooltip="{{tooltipData}}" [options]="{'show-delay': '1000'}">
                                <strong>{{ selectedDocument.name }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Kontakt</td>
                            <td><strong>{{selectedDocument.companyName}}</strong><span *ngIf="selectedDocument.contactShortName"> ({{selectedDocument.contactShortName}})</span></td>

                            <td>Eingegangen</td>
                            <td><strong>{{selectedDocument.timestamp_in * 1000 | date:"dd.MM.y HH.mm"}}</strong></td>
                        </tr>
                        <tr>
                            <td>Bearbeiter</td>
                            <td><strong>{{selectedDocument.userAssignedName}}</strong></td>

                            <td>Freigabe</td>
                            <td>
                                <strong *ngIf="selectedDocument.meta_timestamp_assigned!=0">{{selectedDocument.meta_timestamp_assigned * 1000 | date:"dd.MM.y HH.mm"}}</strong>
                                <strong *ngIf="selectedDocument.meta_timestamp_assigned==0">Nicht erfasst</strong>

                            </td>
                        </tr>
                        <tr>
                            <td style="width:15%;" nowrap="nowrap">Gruppe</td>
                            <td style="width:53%"><strong>{{selectedDocument.groupName}}</strong></td>

                            <td style="width:12%" nowrap="nowrap">Belegdatum</td>
                            <td style="width:26%"><strong>{{selectedDocument.dateString}}</strong></td>
                        </tr>
                        <tr>
                            <td style="height:24px;">Tags</td>
                            <td>
                        <span class="tagline">
                            <span *ngFor="let tag of tagsList" class="form">
                                <button style="min-width: 30px !important; width: fit-content;">{{tag.name}}</button>
                            </span>
                        </span>
                            </td>
                            <td>
                                Id
                                <img (click)="markAsTestDocument(false)" *ngIf="selectedDocument.testDocument != null" src="../design/icons/bullet_green.png" style="vertical-align: sub;">
                                <img (click)="markAsTestDocument(true)" *ngIf="selectedDocument.testDocument == null" src="../design/icons/bullet_white.png" style="vertical-align: sub;">
                            </td>
                            <td><strong>{{selectedDocument.id}}</strong></td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="selectedDocument.id>0 && !editMode && currentSelectedTab===2" class="form" >
                    <div style="height: 26px; width:100% ;">
                        <table class="data_tabel light" style="width:100%;" cellpadding="6" cellspacing="0">
                            <tr>
                                <th style="width:130px;">Zeit/Benutzer</th>
                                <th>Quelle/Ereignis</th>
                            </tr>
                        </table>
                    </div>
                    <div style="height: calc( 100% - 28px ); width:100%; overflow: auto; position:absolute;">
                        <table class="data_tabel light" style="width:100%;" cellpadding="6" cellspacing="0">
                            <tr *ngFor="let x of selectedDocumentLog">
                                <td style="width:130px;" nowrap>
                                    <strong>{{x.create_timestamp * 1000 | date:"dd.MM.yy" }}</strong> - {{x.create_timestamp * 1000 | date:"HH:mm:ss" }}<br>
                                    {{x.user_name}}<span *ngIf="x.user_name==''">System</span>
                                </td>
                                <td style="vertical-align: top;"><strong>{{x.modul}} <span *ngIf="x.name">- {{x.name}}</span>:</strong> {{x.text}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div *ngIf="selectedDocument.id>0 && editMode" class="form" style="margin-left:10px;">
                    <table style="width:99%;"  >
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Titel</span>
                                <br>
                                <input type="text" id="editname" name="editname" [(ngModel)]="editDocument.meta_title" style="width:calc( 100% - 6px );" tabindex="100">
                            </td>
                            <td colspan="2">
                                <span class="formCaption">Firma / Kontakt</span>
                                <br>
                                <app-contact-manager id="processSelectedContact"
                                                     mode="dms_assign"
                                                     [(company)]="editDocument.meta_fk_company"
                                                     [(contact)]="editDocument.meta_fk_contact"
                                                     style="width: 100%;"
                                                     [tabIndex]="102">
                                </app-contact-manager>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <span class="formCaption">Gruppe</span>

                                <select [(ngModel)]="editDocument.meta_fk_documentgroup"
                                        (change)="getEditDocumentTags()"
                                        id="selectedDocumentCategory1"
                                        style="width:100%;"
                                        tabindex="103">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of documentGroupDataSource" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                            <td style="width: 130px;">
                                <span class="formCaption">Datum</span>
                                <br>
                                <span app-date-picker
                                      [(model)]="editDocument.dateString"
                                      id="editDocumentDateString"
                                      tabindex="104">
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <span class="formCaption">Tags</span><br>
                                <span class="tagline"><span *ngFor="let x of tagsList" class="form"><button>{{x.name}} <img
                                        src="../design/icons/delete.png" (click)="tagRemove(x)"></button></span></span>

                                <select *ngIf="tagsList != undefined && tagsList.length < 4"
                                        [(ngModel)]="editDocumentTag"
                                        (change)="documentTagClicked()"
                                        id="currentSelectedMetaTags"
                                        style="width: 150px;"
                                        tabindex="106">
                                    <option value="-1">Bitte wählen</option>
                                    <option *ngFor="let x of editDocumentTagDataSource" [value]="x.id">{{x.name}}</option>
                                </select>
                            </td>
                            <td class="form">
                                <button style="float:right;margin-top:19px; min-width: 95px;" (click)="saveEdit()" tabindex="107"><img src="../design/icons/disk.png"> Speichern </button>
                                <button (click)="toggleExtraMetaText()"
                                        [ngClass]="{'marked' : hastMetaExtraInfos }"
                                        style="float:right;min-width:24px !important;width:24px;height:30px; margin-right:7px;margin-top: 19px;"
                                        tabindex="3"
                                        tooltip="Zusatzinformationen erfassen die dem Suchtext angehängt werden.">
                                    <img *ngIf="documentTop==140" src="../design/icons/sort_arrow_down.png">
                                    <img *ngIf="documentTop>140" src="../design/icons/sort_arrow_up.png">
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td class="form" colspan="4">
                                <span class="formCaption">Zusatzinformationen</span><br>
                                <textarea [(ngModel)]="editDocument.meta_extra_text" style="width:calc( 100% - 8px ); height:120px;"></textarea>
                            </td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="selectedDocument.id > 0 && currentSelectedTab!==2" style="position: absolute;top:{{documentTop}}px;left:0px;right:0px;bottom:0px; background:gray;">
                    <iframe [src]="fileToLoad | safeurl" id="documentView" name="documentView" *ngIf="fileToLoad != null" style="width:100%;height:100%;overflow: hidden; border: none;"></iframe>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Löschen
        </span>
    </ng-template>
</context-menu>

<jw-modal id="noDocumentGroup" class="shadow form glow" >
    <div class="modalHeader">
        <img src="../design/icons/user_go.png" style="margin-top: 8px;margin-left: 10px;">&nbsp;&nbsp;&nbsp;&nbsp; <strong>Pflichtfelder ausfüllen</strong>
    </div>
    <div class="modalContent">
        Bitte alle Pflichtfelder (Firma/Kontakt, Gruppe, Datum) ausfüllen
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="this.message.close('noDocumentGroup');" style="margin-top: 3px;margin-left: 3px; float: right;"><img src="../design/icons/user_go.png"> Bestätigen</button>
    </div>
</jw-modal>

<jw-modal id="autoRecognitionDebugData" class="shadow form glow" >
    <div class="modalHeader">
        <img src="../design/icons/information.png" style="margin-top: 8px;margin-left: 10px;">&nbsp;&nbsp;&nbsp;&nbsp; <strong>Autoerkennungs Daten</strong>
    </div>
    <div class="modalContent" [innerHTML]="autoRecognitionDebugText | safe">

    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="this.message.close('autoRecognitionDebugData');" style="margin-top: 3px;margin-left: 3px; float: right;">Schließen</button>
    </div>
</jw-modal>

<jw-modal id="tagDependencyDialog" class="shadow form glow" >
    <div class="modalHeader">
        <img src="../design/icons/user_go.png" style="margin-top: 8px;margin-left: 10px;">&nbsp;&nbsp;&nbsp;&nbsp; <strong>Zu diesem Tag existieren Abhängigkeiten!</strong>
    </div>
    <div class="modalContent">
        <span *ngIf="this.tagDependency != undefined && this.tagDependency[0].type==1">
                    Sie müssen mindestens 1 Tag aus der Liste wählen.
        </span>
        <span *ngIf="this.tagDependency != undefined && this.tagDependency[0].type==0">
                    Wählen Sie optional einen Tag aus der Liste wählen.
        </span>
        <table style="width:100%;" class="data_tabel" cellpadding="6" cellspacing="0">
            <tr *ngFor="let x of this.tagDependency" (click)="addTagDependency(x);">
                <td>{{x.name}}</td>
            </tr>
        </table>
    </div>
</jw-modal>
