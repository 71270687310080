<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footer1Row" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Dokumente > <div class="pathSelector">▼ <select #modSel class="modul_selector" (change)="goTo(modSel)">
                        <option value="document-search">Suche</option>
                        <option value="document-assign">Eingang</option>
                        <option value="document-portal" selected>zur Vorlage</option>
                </select></div>

                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1"
                           [ngClass]="{ 'error' : searchParameter.text != '' }">

                    <app-contact-manager
                            mode="free"
                            onChange="ngOnChanges()"
                            [(company)]="searchParameter.company"
                            [(contact)]="searchParameter.contact"
                            [(site)]="searchParameter.site"
                            [markOnSet]="true"
                            style="width:250px;" >
                    </app-contact-manager>
                    <!-- mode="company" mode="site" mode="phone" mode="email" standard = contact. Muss nicht gesetzt werden!-->

                    <button (click)="toggleExpandedSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="3">
                        <img *ngIf="!expandedSearch" src="../design/icons/sort_arrow_down.png">
                        <img *ngIf="expandedSearch" src="../design/icons/sort_arrow_up.png">
                    </button>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <app-list-search-filter [(model)]="searchParameter" [filter]="searchParameterFilter" [list]="1"
                                            (callback)="favoriteSelected()"></app-list-search-filter>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>

                    <button (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>

                    <span id="processListFilter" app-options [(model)]="tableSettings"
                          (change)="tableOptionChanged()" style="float: right; ;"></span>

                    <div *ngIf="expandedSearch"
                         style="margin-top: 3px;">
                        <span app-date-picker
                              [(model)]="searchParameter.searchDateFrom"
                              [(boundDatepicker)]="searchParameter.searchDateTo"
                              id="dateFrom"
                              tabindex="102"
                              first="true"
                              [markOnSet]="true">
                        </span>

                        <span app-date-picker
                              [(model)]="searchParameter.searchDateTo"
                              [(boundDatepicker)]="searchParameter.searchDateFrom"
                              id="dateTo"
                              tabindex="102"
                              [markOnSet]="true">
                        </span>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;"
                                [ngClass]="{ 'error' : searchParameter.active != '1' }">
                            <option value="1">Aktive</option>
                            <option value="0">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>
                    </div>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th *ngIf="tableSettings.meta_document_date.state" style="width:130px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Dokumenten Datum"  sortColumn="meta_document_date"></span>
                        </th>
                        <th *ngIf="tableSettings.company_name.state" style="width:130px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Firma"  sortColumn="company_name"></span>
                        </th>
                        <th *ngIf="tableSettings.name.state" >
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Name" sortColumn="name"></span>
                        </th>
                        <th *ngIf="tableSettings.create_fk_user.state" style="width:110px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ERSTELLT VON" sortColumn="create_fk_user"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        id="typeList">

                        <td  *ngIf="tableSettings.meta_document_date.state" (click)="edit($event,x)" style="width:130px;text-align: center;">{{ x.meta_document_date * 1000 | date:"dd.MM.y HH.mm"}}</td>

                        <td  *ngIf="tableSettings.company_name.state" (click)="edit($event,x)" style="width:200px;white-space: nowrap;">
                            {{ x.company_name }}
                        </td>

                        <td  *ngIf="tableSettings.name.state" (click)="edit($event,x)">
                            <span *ngIf="x.assigned_fk_user!=settings.data.userId">
                                <img src="../design/icons/group_go.png" style="vertical-align: sub;" tooltip="In Vertretung für {{x.assigned_user_name}}">
                            </span>
                            <B>{{x.groupName}} <span *ngIf="x.tag!=''"> - {{x.tag}}</span></B> - {{ x.name }}
                        </td>

                        <td  *ngIf="tableSettings.name.state" (click)="edit($event,x)" style="width:110px;">{{ x.create_username }}</td>
                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>

            <div id="leftContainerBottom">
                <span class="form">
                    <select (change)="checkboxActionDropdownChanged()"
                            [(ngModel)]="checkboxActionDropdown"
                            id="checkboxActionDropdown"
                            style="margin-left: 5px; margin-top: 2px; width:150px; ">
                        <option value="-1">Aktion wählen</option>
                        <option *ngFor="let x of checkboxActionDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                    <select *ngIf="checkboxActionDropdown !== '-1'"
                            [(ngModel)]="checkboxActionParameterDropdown"
                            id="checkboxActionParameterDropdown"
                            style="margin-left: 0; margin-top: 2px;width:150px;  ">
                        <option value="-1">Parameter wählen</option>
                        <option *ngFor="let x of checkboxActionParameterDropdownData" value="{{x.value}}">{{x.name}}</option>
                    </select>

                    <button *ngIf="checkboxActionParameterDropdown !== '-1'"
                            (click)="handleCheckboxAction()">Ausführen
                    </button>
                </span>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:6px; display: inline-block">
                    {{currentSelected.name}}
               </div>
                <div style="margin-right:2px; float: right; display: inline-block">
                    <span style="display: inline-block;margin-right: 6px; margin-top: 6px;">{{currentSelected.id}}</span>
                    <button *ngIf="currentSelected.id>0"
                            tooltip="Dokument per E-Mail versenden oder Vorgang eröffnen."
                            style="min-width:30px;height:30px;top: 0px; float:right; "
                            (click)="createProcessFromDocument()"><img src="../design/icons/page_add.png"></button>
                </div>
            </div>

            <div id="rightContainerContent" style="overflow: hidden; top:36px;">
                <table style="height: 100%; width: 100%; border-collapse: collapse;" cellpadding="0" cellspacing="0">
                    <tr style="height: 100px; border-bottom: 1px solid gray;">
                        <td colspan="2">
                            <span app-form
                                  *ngIf="currentSelected.id>0 && currentSelected.fk_workflow_step > 0 && currentSelected.form_send_timestamp == 0"
                                  [workflowStep]="currentSelected.fk_workflow_step"
                                  [assignedUser]="currentSelected.assigned_fk_user"
                                  (finishAction)="formFinished()">
                            </span>
                        </td>
                    </tr>
                    <tr style="height:80px; border-bottom: 1px solid gray;">
                        <td style="background: lightgrey;width: 40%;">
                            <div style="width:100%; height:100%; overflow: auto;">
                                <div style="width:calc( 100% - 20px);font-size: 12px; " *ngIf="comments!=undefined" [innerHTML]="comments|safe"></div>
                                <div style="width:calc( 100% - 20px);font-size: 12px; " *ngIf="comments==undefined || comments==''">Keine Kommentare vorhanden</div>
                            </div>
                        </td>
                        <td>
                            <table style="width:100%;font-size: 12px; background: #e9e9e9; " border="0" cellspacing="8" *ngIf="currentSelected && currentSelected.id>0">
                                <tr>
                                    <td>Kontakt</td>
                                    <td><strong>{{currentSelected.company_name}}</strong><span *ngIf="currentSelected.contactShortName"> ({{currentSelected.contactShortName}})</span></td>

                                    <td>Eingegangen</td>
                                    <td><strong>{{currentSelected.timestamp_in * 1000 | date:"dd.MM.y HH.mm"}}</strong></td>
                                </tr>
                                <tr>
                                    <td>Bearbeiter</td>
                                    <td><strong>{{currentSelected.assigned_user_name}}</strong></td>

                                    <td>Freigabe</td>
                                    <td>
                                        <strong *ngIf="currentSelected.meta_timestamp_assigned!=0">{{currentSelected.meta_timestamp_assigned * 1000 | date:"dd.MM.y HH.mm"}}</strong>
                                        <strong *ngIf="currentSelected.meta_timestamp_assigned==0">Nicht erfasst</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td nowrap="nowrap">Gruppe</td>
                                    <td><strong>{{currentSelected.groupName}}</strong></td>

                                    <td nowrap="nowrap">Belegdatum</td>
                                    <td><strong>{{currentSelected.meta_document_date * 1000 | date:"dd.MM.y"}}</strong></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr style="background: #3d3d3d;">
                        <td colspan="2">
                            <div *ngIf="currentSelected.id > 0" style="height: 100%;">
                                <iframe *ngIf="fileToLoad != null" [src]="fileToLoad | safeurl" id="documentView" name="documentView" style="width:100%;height:100%;overflow: hidden; border: none;"></iframe>
                            </div>
                        </td>
                    </tr>
                </table>
            <div>
        </div>
        </div>
        </div>
    </as-split-area>
</as-split>

<jw-modal id="editorWarning" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>
