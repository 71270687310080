<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footerNone" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Vorgangsmanagement > Arbeitsbelege
                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:90px;"
                           tabindex="1">

                    <app-contact-manager
                            [mode]="'free'"
                            [(company)]="searchParameter.company" (change)="delayedSearch()"
                            [(site)]="searchParameter.site"
                            [(contact)]="searchParameter.contact"
                            style="width:200px;" >
                    </app-contact-manager>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.closed"
                                style="width:90px;">
                            <option value="0">Offen</option>
                            <option value="1">Geschlossen</option>
                            <option value="-1">Alle</option>
                        </select>

                    <select (change)="search(false)"
                            [(ngModel)]="searchParameter.type"
                            id="searchUser"
                            style="width:90px;"
                            tabindex="4">
                        <option value="-1">Alle</option>
                        <option value="8">Servicenachweise</option>
                        <option value="26">Interne Arbeiten</option>
                    </select>

                    <span app-date-picker
                          [(model)]="searchParameter.dateFrom"
                          [(boundDatepicker)]="searchParameter.dateTo"
                          id="dateFrom"
                          tabindex="102"
                          first="true">
                    </span>

                    <span app-date-picker
                          [(model)]="searchParameter.dateTo"
                          [(boundDatepicker)]="searchParameter.dateFrom"
                          id="dateTo"
                          tabindex="102"
                          style="margin-left:2px;">
                    </span>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:2px; "
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            style=" margin-left: 1px;"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"> Suchen
                    </button>


                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th style="width:70px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="DATUM"  sortColumn="send_time"></span>
                        </th>
                        <th style="width:90px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="TID-BELEG" sortColumn="tid_workdocument"></span>
                        </th>
                        <th>
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="TITEL" sortColumn="titel"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="KONTAKT" sortColumn="contact"></span>
                        </th>
                        <th style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="TECHNIKER" sortColumn="engineer"></span>
                        </th>
                        <th style="width:25px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="TYP" sortColumn="typ"></span>
                        </th>
                        <th style="width:70px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ZEIT" sortColumn="time_duration"></span>
                        </th>
                        <th style="width:100px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="ART" sortColumn="billing_type"></span>
                        </th>
                        <th style="width:20px;">

                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent">
                <div class="loading" *ngIf="!data"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        [contextMenu]="basicMenu"
                        [contextMenuValue]="x"
                        id="typeList">

                        <td (click)="edit($event,x)" style="width:70px;">&nbsp;{{ x.send_time }}</td>

                        <td (click)="edit($event,x)" style="width:90px;">{{ x.tid_workdocument }}</td>

                        <td (click)="edit($event,x)">{{ x.title }}</td>

                        <td (click)="edit($event,x)" style="width:150px;">{{ x.contact }}</td>

                        <td (click)="edit($event,x)" style="width:150px;">{{ x.engineer }}</td>

                        <td (click)="edit($event,x)" style="width:25px;"><span *ngIf="x.typ == '8'"><img src="../design/icons/car.png"></span><span *ngIf="x.typ == '26'"><img src="../design/icons/house.png"></span></td>

                        <td (click)="edit($event,x)" style="width:70px;">{{ x.time_duration }}</td>

                        <td (click)="edit($event,x)" style="width:100px;">{{ x.billing_type }}</td>

                        <td [hidden]="x.document_link > 0" style="width:20px;" (click)="openWorkdocument(x.id,x.tid,x.typ, x.tid_workdocument)">
                            <img src="../design/icons/printer.png">
                        </td>
                        <td [hidden]="x.document_link == null" style="width:20px;" (click)="getSelectedWorkdocument(x)">
                            <img src="../design/icons/doc_pdf.png">
                        </td>
                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>
        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone" >
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:5px;">
                    <span *ngIf="currentSelected.id>0">
                        Verlauf des Gewählten Arbeitsbelegs : {{currentSelected.tid_workdocument}}
                    </span>
                </div>
            </div>

            <div id="rightContainerContent">
                <div *ngIf="!showFinishedWorkdocument && currentSelected.id>0" class="form" style="margin-left:10px;">
                    <span app-process-view [tid]="currentSelected.tid" [mark]="currentSelected.id"></span>
                </div>

                <div *ngIf="showFinishedWorkdocument && currentSelected.id>0" class="form" style="margin-left:10px;">
                    <div style="position: absolute;top:0;left:0px;right:0px;bottom:0px; overflow-x: hidden;">
                        <iframe [src]="selectedWorkdocument | safeurl" id="documentView" name="documentView" *ngIf="selectedWorkdocument != null" style="width:100%;height:100%;overflow: hidden; border: none;"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </as-split-area>
</as-split>

<jw-modal id="fullscreenReading" class="jw-fullscreen shadow">
    <div style="height:25px; width:100%;"><div style="margin-top:3px; margin-left:4px; display: inline-block;">{{fullscreenFilename}}</div><span style="float: right; display: inline-block; margin-right:4px; margin-top:3px;"><img (click)="closeFullscreen()" src="../design/icons/cross_black.png"></span></div>
    <iframe *ngIf="iframeFullscreenSource" [src]="iframeFullscreenSource | safeurl" style="background-color:#585858; height:calc( 100% - 25px ); width:100%; border: 1px solid #575757;"></iframe>
</jw-modal>

 <context-menu #basicMenu>
    <ng-template contextMenuItem (execute)="startTextSearchFromContextmenu($event.value)" >
        <span style="margin: 6px; display: inline-block;">
            <img src="../design/icons/magnifier.png" style="vertical-align: middle">&nbsp;&nbsp;Suchen
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="activate($event.value)" >
        <span *ngIf="item?.active == 0" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/accept.png" style="vertical-align: middle">&nbsp;&nbsp;Aktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="deactivate($event.value)">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/delete.png" style="vertical-align: middle">&nbsp;&nbsp;Deaktivieren
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item divider="true" ></ng-template>

    <ng-template contextMenuItem let-item>
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <B>Sortierung</B>
        </span>
    </ng-template>

    <ng-template contextMenuItem divider="true"></ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'start')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_top.png" style="vertical-align: middle">&nbsp;&nbsp;Anfang
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'up')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_up.png" style="vertical-align: middle">&nbsp;&nbsp;Hoch
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'down')">
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_down.png" style="vertical-align: middle">&nbsp;&nbsp;Runter
        </span>
    </ng-template>

    <ng-template contextMenuItem let-item (execute)="changeSort($event.value.id,'end')" >
        <span *ngIf="item?.active == 1" style="margin: 6px; display: inline-block;">
            <img src="../design/icons/bullet_arrow_bottom.png" style="vertical-align: middle">&nbsp;&nbsp;Ende
        </span>
    </ng-template>
</context-menu>
