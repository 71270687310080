<as-split direction="horizontal">
    <as-split-area size="{{leftContainerWidth}}">
        <div id="leftContainerDefinition" class="header3Row footerNone" >
            <div id="leftContainerTop">
                <span *ngIf="searchResultCount>data.length" class="listCounter">{{data.length}} von {{searchResultCount}} Einträgen</span>
                <span *ngIf="searchResultCount<=data.length" class="listCounter">{{searchResultCount}}
                    <span *ngIf="searchResultCount>1">Einträge</span>
                    <span *ngIf="searchResultCount==1">Eintrag</span></span>
                <span class="currentLocation">
                    Benachrichtigung > <select #modSel class="modul_selector" (change)="goTo(modSel)">
                        <option value="core-information/0" selected>Eingang</option>
                        <option *ngIf="creatorRight == true && creatorSpace == false" value="core-information">Verwaltung</option>
                        <option *ngIf="creatorSpace == true" selected value="core-information">Verwaltung</option>
                    </select>

                </span>

                <span class="form search">
                    <input type="text"
                           (keydown)="searchOnReturnPressed($event)"
                           [(ngModel)]="searchParameter.text"
                           id="searchText"
                           style="width:256px;"
                           tabindex="1"
                           [ngClass]="{ 'error' : searchParameter.text != '' }">


                        <span app-date-picker
                              [(model)]="searchParameter.dateFrom"
                              [(boundDatepicker)]="searchParameter.dateTo"
                              id="dateFrom"
                              tabindex="102"
                              first="true"
                              [markOnSet]="true">
                        </span>

                        <span app-date-picker
                              [(model)]="searchParameter.dateTo"
                              [(boundDatepicker)]="searchParameter.dateFrom"
                              id="dateTo"
                              tabindex="102"
                              [markOnSet]="true">
                        </span>

                        <select (change)="search(false)"
                                [(ngModel)]="searchParameter.active"
                                style="width:80px;"
                                [ngClass]="{ 'error' : searchParameter.active != '0' }">
                            <option value="0">Aktive</option>
                            <option value="1">Inaktive</option>
                            <option value="-1">Alle</option>
                        </select>

                    <button (click)="resetSearch()"
                            style="min-width:24px !important;width:24px;height:30px; margin-left:-1px;"
                            tabindex="4">
                        <img src="../design/icons/cross_black.png" style="margin-top:1px; margin-left:-1px;">
                    </button>

                    <button (click)="search(false)"
                            tabindex="5">
                        <img src="../design/icons/magnifier.png"  style="vertical-align: sub;"> Suchen
                    </button>

                    <button *ngIf="creatorSpace == true" (click)="new()"
                            style="float:right; margin-right:8px;"
                            tabindex="6">
                        <img src="../design/icons/add.png"> Neu
                    </button>
                </span>

                <table cellpadding="4" cellspacing="0" class="data_tabel header" style="width:100%">
                    <tr>
                        <th *ngIf="tableSettings.name.state" >
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Betreff" sortColumn="name"></span>
                        </th>
                        <th *ngIf="tableSettings.name.state" style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Datum" sortColumn="create_timestamp"></span>
                        </th>
                        <th *ngIf="tableSettings.create_fk_user.state" style="width:150px;">
                            <span app-sort-switch (onSortChanged)="search(false)" [(model)]="searchParameter.sort" name="Ersteller" sortColumn="creator"></span>
                        </th>
                    </tr>
                </table>
            </div>

            <div id="leftContainerContent" >
                <div class="loading" *ngIf="data != undefined && data.length < 1 && showLoading"><img src="../design/icons/roller.gif" style="margin-left: 45%; margin-top: 25%; width: 50px;"></div>
                <table class="data_tabel" style="width: 100%" cellpadding="4" cellspacing="0" >
                    <tr *ngFor="let x of data"
                        id="{{x.id}}"
                        [ngClass]="{'selected' : currentSelected.id == x.id}"
                        id="typeList">

                        <td style="width: 20px;" *ngIf="creatorSpace == true">
                            <img *ngIf="x.read_by_everyone == 0" src="../design/icons/tickdoublegrey.png">
                            <img *ngIf="x.read_by_everyone == 1" src="../design/icons/tickdouble.png">
                            <img *ngIf="x.read_by_everyone == -1" src="../design/icons/tick_inactive.png">
                        </td>

                        <td  *ngIf="tableSettings.name.state" style=" border-right: none !important;" (click)="edit($event,x)">{{ x.name }}</td>

                        <td style="width: 20px;  border-left: none !important;"> <img *ngIf="x.fk_document > 0" src="../design/icons/attach.png"></td>

                        <td  *ngIf="tableSettings.name.state" (click)="edit($event,x)" style="width:150px;">{{ x.createDate }}</td>

                        <td  *ngIf="tableSettings.create_fk_user.state" (click)="edit($event,x)" style="width:150px;">{{ x.creator }}</td>

                    </tr>
                </table>

                <button style="margin:20px auto; display: block;" (click)="search(true)"
                        *ngIf="data.length > 0 && data.length<searchResultCount">Weitere Ergebnisse Anzeigen
                </button>
            </div>


        </div>
    </as-split-area>

    <as-split-area size="{{rightContainerWidth}}">
        <div id="rightContainerDefinition" class="header1Row footerNone">
            <div id="rightContainerTop">
                <div style="margin-left:10px; margin-top:0px;">


                    <span>
                    <button *ngIf="(showNewItemForm) && creatorSpace == true" [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 1 || currentSelectedTab === 4 }" style="margin-right:5px;" >
                         <span *ngIf="showNewItemForm">NEU</span>
                    </button>

                    <button *ngIf="currentSelected.id > 0 && showNewItemForm == false" [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 2 }" style="margin-right:5px;" (click)="changeTabButton(2)">
                        <span>Benachrichtigung</span>
                    </button>

                    <button *ngIf="currentSelected.id > 0 && creatorSpace == true && showNewItemForm == false && canBeEdited == false" [ngClass]="{'tab' : true , 'tab_selected' : currentSelectedTab === 3 }" style="margin-right:5px;" (click)="changeTabButton(3)">
                        <span>Empfänger</span>
                    </button>
                    </span>


                </div>
            </div>

            <div id="rightContainerContent" style="overflow: hidden !important;">
                <div *ngIf="((showNewItemForm && currentSelectedTab == 1) || canBeEdited == true) && creatorSpace == true" class="form" style="margin-left:10px;">
                    <table style="width:99%;" cellpadding="6" >
                        <tr>
                            <td>
                                <span class="formCaption">Name *</span>
                                <input [(ngModel)]="currentSelected.name"
                                       [ngClass]="{ 'error' : currentSelected.name == '' && formError }"
                                       type="text"
                                       id="currentSelectedName"
                                       style="width:100%;"
                                       tabindex="101">
                            </td>

                            <td>
                                <span class="formCaption">Stichtag *</span><br>
                                <span app-date-picker
                                      [(model)]="currentSelected.timestampString"
                                      id="currentSelectedTimestamp"
                                      [tabindex]="102"
                                      [mark]="formError && currentSelected.timestampString ==''" >
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Empfängergruppe *</span><br>
                                <select (change)="addUserGroup()"
                                        [(ngModel)]="currentSelected.userGroup"
                                        id="searchUser"
                                        style="width:150px;"
                                        tabindex="4"
                                        [ngClass]="{ 'error' : searchParameter.user != '' }">
                                    <option value="">Alle Gruppen</option>
                                    <option value="Nachricht an alle Benutzer">Nachricht an alle Benutzer</option>
                                    <option *ngFor="let x of dropdownDataUser" [value]="x.name">{{x.name}}</option>
                                </select>
                            </td>
                        </tr>
                        <table class="data_tabel" style="width: 100%">
                        <tr  *ngFor="let x of groupArray"><td  colspan="2">{{x}}</td>  <td style="width: 30px" (click)="activateTooltip(x)">
                            <img style="margin-left: 8px" src="../design/icons/group.png">
                        </td><td style="width: 30px" (click)="removeUserGroup(x)">
                            <img style="margin-left: 8px" src="../design/icons/delete.png">
                        </td></tr></table>
                        <tr>
                            <td colspan="2">
                                <span class="formCaption">Text *</span>
                                <editor onfocus="let elem = this.getElementsByTagName('iframe')[0];elem.tabIndex = this.tabIndex;setTimeout(() => { elem.contentWindow.document.body.focus(); }, 100);"
                                        [(ngModel)]="currentSelected.text"
                                        [init]="settings.tinyMceInit"
                                        [ngClass]="{ 'error' : currentSelected.text == '' && formError, 'noForm' : true }"
                                        apiKey="lm9997ms2km4hkmma0yno2r1os2kvcmyise4b3mf6cx0gd4z"
                                        tabindex="104">
                                </editor>

                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <button (click)="save(1)" style="width: 300px; float: right;" tabindex="106">
                                    <img src="../design/icons/disk.png"> ohne Dokument speichern
                                </button>
                                <button (click)="save(2)" style="width: 300px; float: right;" tabindex="106">
                                    <img src="../design/icons/disk.png"> Dokument aus DMS anhängen
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>

                <div *ngIf="currentSelectedTab == 3">
                    <table class="data_tabel" style="width: 100%">
                        <tr *ngFor="let x of groupReceiverArray"><td colspan="2">{{x.name}}, {{x.first_name}}</td>
                            <td *ngIf="x.read_timestamp > 0" style="text-align: center; width: 20%">{{x.timestampString}}</td>
                            <td *ngIf="x.read_timestamp == 0" style="text-align: right; width: 20%"></td>
                            <td style="width: 30px">
                            <img *ngIf="x.read_timestamp == 0" style="margin-left: 8px" src="../design/icons/tickdoublegrey.png">
                            <img *ngIf="x.read_timestamp > 0" style="margin-left: 8px" src="../design/icons/tickdouble.png">
                        </td></tr></table>
                </div>

                <div *ngIf="(currentSelected.id > 0 && (creatorSpace == false || (creatorSpace == true && showNewItemForm == false)) && currentSelectedTab == 2 ) && canBeEdited == false">
                    <div style="margin-left:10px; margin-top:6px;" class="form" style="height: 20%; width: 99%; position: absolute; overflow: auto;" >
                    <table style="width: 100%">
                        <tr><td>   <span class="noMailText" [innerHTML]="currentSelected.text"></span> </td></tr>
                    </table>
                    </div>
                    <div *ngIf="creatorSpace == false" style="position: absolute; height: 3%; left:0px;right:0px;bottom:77%; background: white;">       <table style="width: 100%">
                        <tr><td><button *ngIf="creatorSpace == false && searchParameter.active == 0" (click)="markReaded()" style="float: right; ">Als gelesen Bestätigen</button></td></tr>
                    </table>  </div>
                <div *ngIf="currentSelected.id > 0 && creatorSpace == false" style="position: absolute; height: 76%; left:0px;right:0px;bottom:0px; background: gray;">
                    <iframe [src]="fileToLoad | safeurl" id="documentView" name="documentView" *ngIf="fileToLoad != null" style="width:100%;height:100%;overflow: hidden; border: none;"></iframe>
                </div>
                    <div *ngIf="(currentSelected.id > 0 && creatorSpace == true) || (creatorSpace == false && (searchParameter.active == 1 || searchParameter.active == -1))" style="position: absolute; height: 79.5%; left:0px;right:0px;bottom:0px; background: gray;">
                        <iframe [src]="fileToLoad | safeurl" *ngIf="fileToLoad != null" style="width:100%;height:100%;overflow: hidden; border: none;"></iframe>
                    </div>
                </div>

                    </div>
                </div>


    </as-split-area>
</as-split>

<jw-modal id="editorWarning" class="jw-modal shadow">
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Hinweis
        </div>
    </div>
    <div style="margin:20px; line-height: 1.4;" class="form" [innerHTML]="editorWarningString | safe">
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('editorWarning');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>

<jw-modal id="groupUsers" class="jw-modal shadow" >
    <div class="modalHeader">
        <div>
            <img src="../design/icons/exclamation.png" style="vertical-align: text-bottom; margin-right:4px;"> Gruppenmitglieder
        </div>
    </div>
    <div style="margin:20px; height: 250px; width: 250px; overflow: auto;" class="form">
        <table class="data_tabel" style="width: 100%; " cellpadding="4" cellspacing="0" >
            <tr  *ngFor="let x of groupUsers"><td  colspan="2">{{x.name}}</td>
            </tr></table>
    </div>
    <div class="modalFooter form" style="text-align: right">
        <button (click)="message.close('groupUsers');" style="margin-top: 3px;margin-left: 3px; float: right;">
            Schließen
        </button>
    </div>
</jw-modal>
